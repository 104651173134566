<template>
  <div class="app_bj">
      <div class="login_box">
          <div class="qrcode">
               <div class="code" id="qrcode" ref="qrcode"></div>
               <div ng-show="!isNeedRefresh" class="">
                    <p class="sub_title">使用手机微信扫码登录</p>
                </div>
          </div>
         
      </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';
import {loginParam,getTokenUser,getTokenFn} from '@/api';
import { getToken, setToken, removeToken } from '@/utils/auth'
export default {
    data(){
        return{
            timer:null
        }
    },
    mounted(){
        this.getParam()
    },
    methods:{
     getParam(){
        //  getTokenFn().then(res=>{
        //     console.log(res)
        //     this.$store.commit('SET_USER',res.data)
        //     setToken('Admin-Token',res.data.token)
        //     this.$router.push({
        //         path:'/index'
        //     })
        //  })
       loginParam().then(res=>{
         console.log(res)
         if(res.code == 200){
           setToken('Admin-Token',res.data.state)
           let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${res.data.appid}&redirect_uri=${res.data.redirect_uri}&response_type=code&scope=${res.data.scope}&state=${res.data.state}#wechat_redirect"`
           this.qrcode = new QRCode('qrcode', {
            text:url,
            width:240,
            height: 240,
          });
          this.checkToken()
         }
       })
     },
     checkToken(){
         this.timer = setInterval(() => {
            getTokenUser().then(res=>{
                if(res.data){
                    console.log(res)
                    this.$store.commit('SET_USER',res.data)
                    console.log(res.data)
                    setToken('userInfo',JSON.stringify(res.data))
                    clearInterval(this.timer);
                    this.$router.push({
                        path:'/index'
                    })
                }
            })
         }, 1000)
     }
    }
}
</script>

<style>
.app_bj{
    background: url('../assets/images/2zrdI1g.jpg') no-repeat 50%;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
}
.login_box{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -190px;
    margin-top: -206px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    background-color: #fff;
    width: 380px;
    height: 412px;
    box-shadow: 0 2px 10px #999;
    -moz-box-shadow: #999 0 2px 10px;
    -webkit-box-shadow: #999 0 2px 10px
}
#qrcode{
    width: 240px;
    height: 240px;
    margin: 60px auto 12px;
}
.login_box .qrcode img {
    display: block;
    width: 240px;
    height: 240px;
}
.login_box .qrcode .img.qrcode_expired {
    opacity: .1
}

.login_box .qrcode .sub_title {
    text-align: center;
    font-size: 20px;
    color: #353535;
    margin-bottom: 23px
}

.login_box .qrcode .sub_desc {
    text-align: center;
    color: #a3a3a3;
    font-size: 15px;
    padding: 0 40px;
    line-height: 1.8
}

.login_box .qrcode .sub_desc a {
    text-decoration: none;
    color: #576b95
}

.login_box .qrcode .refresh_qrcode_mask {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 270px
}

.login_box .qrcode .refresh_qrcode_mask .icon-refresh {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -48px;
    margin-top: -48px;
}
</style>